<template>
    <Card class="mt-5 no-border no-padding DadosDoAgendamentostep0">
        <template #title>
            Dados do agendamento <TourComponent tourName="DadosAgendamentoGuiaGestaoMedica"/>
        </template>
        <template #content>
            <div class="p-fluid grid">
                <div class="field col-4 m-0 DadosDoAgendamentostep1">
                    <label :class="{ 'p-error': v$.form.nome.$invalid && submitted }" for="nome">
                        Nome do solicitante <span class="p-error">*</span>
                    </label>
                    <InputText
                        id="nome"
                        v-model.trim="form.nome"
                        :class="{ 'p-invalid': v$.form.nome.$invalid && submitted }"
                        autofocus
                        required="true"
                    />
                    <small v-if="v$.form.nome.$invalid && submitted" class="p-error">
                        {{ v$.form.nome.required.$message }}
                    </small>
                </div>
                <div class="field col-4 m-0 DadosDoAgendamentostep2">
                    <label :class="{ 'p-error': v$.form.email.$invalid && submitted }" for="email">
                        E-mail do solicitante <span class="p-error">*</span>
                    </label>
                    <InputText id="email" v-model.trim="form.email" :class="{ 'p-invalid': v$.form.email.$invalid && submitted }" required="true" />
                    <small v-if="v$.form.email.$invalid && submitted" class="p-error">
                        {{ v$.form.email.required.$message }}
                    </small>
                </div>
                <div class="field col-4 m-0 DadosDoAgendamentostep3">
                    <label :class="{ 'p-error': v$.form.telefone.$invalid && submitted }" for="telefone">
                        Telefone do solicitante <span class="p-error">*</span>
                    </label>
                    <InputMask
                        id="telefone"
                        v-model.trim="form.telefone"
                        :class="{ 'p-invalid': v$.form.telefone.$invalid && submitted }"
                        mask="(99) 99999-9999"
                        required="true"
                    />
                    <small v-if="v$.form.telefone.$invalid && submitted" class="p-error">
                        {{ v$.form.telefone.required.$message }}
                    </small>
                </div>
                <div class="field col-8 m-0 DadosDoAgendamentostep4">
                    <label :class="{ 'p-error': v$.form.tipoExame.$invalid && submitted }" for="tipoExame">
                        Tipo de exame <span class="p-error">*</span>
                    </label>
                    <Dropdown
                        id="tipoExame"
                        v-model="form.tipoExame"
                        :class="{ 'p-invalid': v$.form.tipoExame.$invalid && submitted }"
                        :options="tiposExames"
                        :showClear="true"
                        optionLabel="descricao"
                        placeholder="Selecione o tipo de exame..."
                    />
                    <small v-if="v$.form.tipoExame.$invalid && submitted" class="p-error">
                        {{ v$.form.tipoExame.required.$message }}
                    </small>
                </div>
                <div class="field col-4 m-0 DadosDoAgendamentostep5">
                    <label :class="{ 'p-error': v$.form.cidade.$invalid && submitted }" for="cidade"> Cidade <span class="p-error">*</span> </label>
                    <DropdownCidade
                        id="cidade"
                        v-model="form.cidade"
                        :class="{ 'p-invalid': v$.form.cidade.$invalid && submitted }"
                        :filterInputProps="{ 'data-test-id': 'cidade-filter-input' }"
                        @change="changeCidade"
                    />
                    <small v-if="v$.form.cidade.$invalid && submitted" class="p-error">
                        {{ v$.form.cidade.required.$message }}
                    </small>
                </div>
                <div class="field col-12 m-0 DadosDoAgendamentostep6">
                    <label :class="{ 'p-error': form.funcionarios.length === 0 && v$.form.funcionarios.$invalid && submitted }" for="funcionarios">
                        Funcionário(s) <span class="p-error">*</span>
                    </label>
                    <div class="p-inputgroup flex-1">
                        <MultiSelectFuncionario
                            v-model="selectedFuncionarios"
                            :customerId="this.form.cliente?.id"
                            :loading="isAddingFuncionario"
                            :situacao="'ATIVO,AFASTADO,PENDENTE'"
                            :exame="form.tipoExame?.id ? form.tipoExame.id : null"
                            @change="setCustomerId"
                        />
                        <Button
                            :disabled="!selectedFuncionarios"
                            :loading="isAddingFuncionario"
                            class="p-button-outlined DadosDoAgendamentostep7"
                            label="Adicionar"
                            @click="adicionarFuncionario()"
                        />
                    </div>
                    <small v-if="form.funcionarios.length === 0 && v$.form.funcionarios.$invalid && submitted" class="p-error">
                        {{ v$.form.funcionarios.required.$message }}
                    </small>
                    <small v-if="v$.selectedFuncionarios.$invalid && submitted" class="p-error">
                        Existem funcionários selecionados que não foram adicionados
                    </small>
                </div>
                <div v-if="form.funcionarios.length" class="col-12 pt-0 DadosDoAgendamentostep8">
                    <DataTable :value="form.funcionarios">
                        <Column field="name" header="Nome" style="width: 40%"></Column>
                        <Column header="E-mail" style="width: 30%">
                            <template #body="{ index }">
                                <InputText v-model="form.funcionarios[index].email" autofocus />
                            </template>
                        </Column>
                        <Column header="Telefone" style="width: 30%">
                            <template #body="{ index }">
                                <div>
                                    <InputMask
                                        v-model="form.funcionarios[index].phone"
                                        :class="{ 'p-invalid': submitted && v$.form.funcionarios.$each.$response.$errors[index].phone.length }"
                                        autofocus
                                        mask="(99) 99999-9999"
                                    />
                                </div>
                                <small v-if="v$.form.funcionarios.$each.$response.$errors[index].phone.length && submitted" class="p-error">
                                    {{ v$.form.funcionarios.$each.$response.$errors[index].phone[0].$message }}
                                </small>
                            </template>
                        </Column>
                        <Column bodyClass="text-right">
                            <template #body="{ index }">
                                <Button class="p-button-text p-button" icon="pi pi-trash" @click="funcionarioRemove(index)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="grid grid-nogutter justify-content-between">
                <i></i>
                <Button :loading="isLoading" class="w-2 DadosDoAgendamentostep9" label="Avançar" @click="nextPage()" />
            </div>
        </template>
    </Card>
    <Dialog
            v-if="showMudancaRiscoDialog"
            v-model:visible="showMudancaRiscoDialog"
            :loading="loading"
            :closable="false"
            :modal="true"
            class="p-fluid"
            header="Atenção!"
            :style="{ width: '40vw' }"
        >
            <div class="field flex-column justify-align-items">
                <div class="confirmation-content field">
                    <i class="pi pi-info-circle mr-2" style="font-size: 2rem" />
                    <span style="font-size: 14px">
                        O colaborador não sofreu alterações de riscos e os exames serão cobrados. Deseja prosseguir com o agendamento?
                    </span>
                </div>
                <div class="field-checkbox ml-2">
                    <Checkbox
                        v-model="cienteCobrancaAso"
                        :binary="true"
                        :class="{ 'p-invalid': submittedMudancaRiscoDialog && !cienteCobrancaAso }"
                    />
                    <label :class="{ 'p-error': submittedMudancaRiscoDialog && !cienteCobrancaAso }">
                        Estou ciente de que os exames serão cobrados.</label
                    >
                </div>
            </div>
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="onClickCloseMudancaRiscoDialog" />
                <Button
                    label="Confirmar"
                    icon="pi pi-check"
                    class="p-button-outlined p-button-success"
                    :loading="loading"
                    :disabled="loading"
                    @click="enviarConfirmacao"
                />
            </template>
        </Dialog>
</template>

<script>
import DropdownCidade from '../../../cidades/components/DropdownCidade.vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import AreaMedicaService from '../../../../services/AreaMedicaService';
import { getProfile } from '../../../../services/auth';
import BaseService from '../../../../services/BaseService';
import { showInfo } from '../../../../utils/Toast';
import { getClientBase } from '../../../../services/http';
import MultiSelectFuncionario from './components/MultiSelectFuncionario.vue';
import TipoExame from '../../../../enums/TipoExame';
import TourComponent from '../../../../components/TourComponent';

const validarTelefone = (telefone) => {
    const telefoneSemMascara = telefone.replace(/[^0-9]+/g, '');
    const regexTelefone = /^(?!.*(\d)\1{10}|1234567890|9876543210)\d{11}$/;

    if (regexTelefone.test(telefoneSemMascara)) {
        return true;
    }
    return false;
};

export default {
    setup: () => ({ v$: useVuelidate() }),
    emits: ['next-page'],
    components: {
        MultiSelectFuncionario,
        DropdownCidade,
        TourComponent
    },
    props: {
        formData: Object,
        isLoading: Boolean
    },
    data() {
        return {
            submitted: false,
            isAddingFuncionario: false,
            form: {
                nome: null,
                email: null,
                telefone: null,
                tipoExame: null,
                cidade: null,
                funcionarios: [],
                cliente: null,
                profile: null
            },
            selectedFuncionarios: null,
            submittedMudancaRiscoDialog: false,
            cienteCobrancaAso: false,
            EnumTipoExame: TipoExame,
            showMudancaRiscoDialog: false,
            tiposExames: []
        };
    },
    validations() {
        return {
            selectedFuncionarios: {
                validateEmpty: this.validateEmpty
            },
            form: {
                nome: { required: helpers.withMessage('O nome do solicitante deve ser informado', required) },
                email: { required: helpers.withMessage('O e-mail do solicitante deve ser informado', required) },
                telefone: { required: helpers.withMessage('O telefone do solicitante deve ser informado', required) },
                tipoExame: { required: helpers.withMessage('O tipo de exame deve ser informado', required) },
                cidade: { required: helpers.withMessage('A cidade deve ser informado', required) },
                funcionarios: {
                    required: helpers.withMessage('Deve ser adicionado no minímo um funcionário', required),
                    $each: helpers.forEach({
                        phone: {
                            validarTelefone: helpers.withMessage('O campo precisa ser informado corretamente', validarTelefone)
                        }
                    })
                }
            }
        };
    },
    async mounted() {
        this.$serviceTiposExames = new AreaMedicaService('/tipo_exames');
        this.$serviceRiscos = new BaseService('/customer/employees/riscos-soc');

        await this.loadTiposExames();

        this.form.profile = await getProfile();
        this.form.nome = this.form.profile.name;
        this.form.email = this.form.profile.email;
        this.form.telefone = this.form.profile.phone;
    },
    async activated() {
        this.form = { ...this.form, ...this.formData };
    },
    watch: {
        cienteCobrancaAso() {
            this.submittedMudancaRiscoDialog = false;
        }
    },
    methods: {
        async nextPage() {
            this.submitted = true;

            const result = await this.v$.$validate();
            if (!result) {
                return;
            }

            this.$emit('next-page', { formData: this.form, pageIndex: 0 });
        },
        async loadTiposExames() {
            const { data } = await this.$serviceTiposExames.findAll({});
            this.tiposExames = data;
        },
        async adicionarFuncionario() {
            const limiteSolicitacoesSimultaneasSOC = 5;
            const funcionariosAgrupados = this.selectedFuncionarios.reduce((grupos, funcionario) => {
                if (grupos.at(-1)?.length < limiteSolicitacoesSimultaneasSOC - 1) {
                    grupos.at(-1).push(funcionario);
                } else {
                    grupos.push([funcionario]);
                }

                return grupos;
            }, []);

            this.isAddingFuncionario = true;
            for (const funcionarios of funcionariosAgrupados) {
                await Promise.all(
                    funcionarios.map(async (funcionario) => {
                        if (this.form.funcionarios.some((element) => element.id === funcionario.id)) {
                            return;
                        }

                        await this.buscarRiscosDoFuncionario(funcionario);
                        await this.buscarExamesDoFuncionario(funcionario);
                        this.form.funcionarios.push(funcionario);
                    })
                );
            }

            this.selectedFuncionarios = null;
            this.isAddingFuncionario = false;
        },
        funcionarioRemove(index) {
            this.form.funcionarios.splice(index, 1);

            if (this.form.funcionarios.length === 0) {
                this.form.cliente = null;
            }
        },
        setCustomerId(option) {
            if (this.form.funcionarios.length === 0) {
                this.form.cliente = null;
            }

            if (option.value[0] && !this.form.cliente) {
                this.form.cliente = option.value[0].customer;
            }
            this.verificaMudancaFuncao(option);
        },
        verificaMudancaFuncao(event) {
            const records = event.value;
            if (!this.form.tipoExame || this.form.tipoExame?.id != this.EnumTipoExame.MUDANCA_FUNCAO) {
                return;
            }
            const algumSemMudancaDeRisco = records.find((p) => !p.mudouRisco);
            if (algumSemMudancaDeRisco) {
                this.submittedMudancaRiscoDialog = false;
                this.showMudancaRiscoDialog = true;
                return;
            }
        },
        async enviarConfirmacao() {
            try {
                this.submittedMudancaRiscoDialog = true;

                if (!this.cienteCobrancaAso) {
                    this.$toast.add({ severity: 'error', summary: 'É necessário marcar o campo para realizar a confirmação do exame.', life: 3000 });
                    return;
                }
                this.form.asoEmDia = true;
                this.$toast.add({ severity: 'success', summary: 'Funcionário agendado com sucesso!', life: 3000 });
                this.showMudancaRiscoDialog = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: error.response?.data?.message || error.message, life: 3000 });
            }
        },
        onClickCloseMudancaRiscoDialog() {
            this.showMudancaRiscoDialog = false;
            this.selectedFuncionarios = [];
        },
        async buscarExamesDoFuncionario(funcionario) {
            const { id, customerId } = funcionario;
            const tipoExameId = this.form.tipoExame?.id;
            try {
                const { data } = await getClientBase().get(
                    `/importacao/procedimentos-soc/funcionario/${id}/customer/${customerId}/tipo-exame/${tipoExameId}`
                );
                if (!data) return;

                data.procedimentos.forEach((el) => {
                    const exame = data.examesEmDiaList.find((item) => item.idExame === el.procedimento.id);
                    el.dataRefazerExame = exame?.dataRefazerExame;
                    el.aceitar = !exame;
                });

                funcionario.exames = data.procedimentos;
                funcionario.procedimentos = data.procedimentos.map((el) => ({ ...el.procedimento }));
                this.form.examesEmDia ??= data.examesEmDia;
            } catch (error) {
                showInfo(this.$toast, 'Não foi possível consultar os Exames no SOC');
            }
        },
        async buscarRiscosDoFuncionario(funcionario) {
            try {
                const { data } = await this.$serviceRiscos.findById(funcionario.id);
                funcionario.riscos = data;
                funcionario.possuiRisco = data.length !== 0;
            } catch (e) {
                showInfo(this.$toast, 'Não foi possível consultar os Riscos no SOC');
            }
        },
        changeCidade() {
            this.form.prestador = null;
        },
        validateEmpty(value) {
            return !(value && value.length);
        }
    }
};
</script>

<style lang="scss" scoped>
.p-card.no-border {
    box-shadow: unset;
}

.p-card.no-padding > :deep(.p-card-body) {
    padding: 0;
}

.shepherd-theme-custom {
    padding: 2;
}
</style>
